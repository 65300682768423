//
// menu.scss
//

// Logo Height
.logo-lg {
    img {
        height: $logo-lg-height;
    }
}

.logo-sm {
    img {
        height: $logo-sm-height;
    }
}

// Sidebar Logo
.logo {
    display: block;
    top: 0;
    position: sticky;
    text-align: center;
    line-height: $topbar-height;
    padding: 0 24px;
    transition: $transition-base;

    span.logo-lg {
        display: block;
    }

    span.logo-sm {
        display: none;
    }

    &.logo-light {
        display: none;
    }

    &.logo-dark {
        display: block;
    }
}

html[data-menu-color="brand"],
html[data-menu-color="dark"],
html[data-bs-theme="dark"] {

    .logo {
        &.logo-light {
            display: block;
        }

        &.logo-dark {
            display: none;
        }
    }
}

// Wrapper
.wrapper {
    height: 100%;
    width: 100%;
}

// Content Page
.content-page {
    position: relative;
    margin-left: $leftbar-width;
    min-block-size: 100vh;
    padding: $spacer calc($spacer * 0.5) $footer-height;
    transition: $transition-base;
}

// Left Side-menu
.leftside-menu {
    z-index: 1000;
    bottom: 0;
    top: 0;
    position: fixed;
    width: $leftbar-width;
    min-width: $leftbar-width;
    background: $menu-bg;
    box-shadow: var(--#{$prefix}box-shadow);
    transition: $transition-base;

    [data-simplebar] {
        height: calc(100% - $topbar-height);
    }
}

// Side-nav
.side-nav {
    list-style-type: none;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    ul {
        list-style-type: none;
    }

    .side-nav-item {
        .side-nav-link {
            display: block;
            list-style: none;
            position: relative;
            white-space: nowrap;
            color: $menu-item-color;
            transition: $transition-base;
            font-size: $menu-item-font-size;
            padding: 10px 12px;

            svg,
            i {
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                font-size: $menu-item-icon-size;
                line-height: $menu-item-icon-size;
                margin-right: 8px;
            }

            svg {
                height: $menu-item-icon-size;
                width: $menu-item-icon-size;
            }

            .menu-arrow {
                display: inline-block;
                text-rendering: auto;
                position: absolute;
                top: 50%;
                right: 10px;
                transition: transform 0.15s;
                transform: translate(-50%, -50%);
                line-height: $menu-item-icon-size;
                font-size: calc($menu-item-font-size * 1.18);

                &:before {
                    content: "\ea6e";
                    font-family: "remixicon";
                }
            }

            &:hover,
            &:focus,
            &:active {
                color: $menu-item-hover-color;
                text-decoration: none;
            }

            &[aria-expanded="true"] {
                .menu-arrow {
                    transform: translate(-50%, -50%) rotate(90deg);
                }
            }

            &.menuitem-active {
                >a:not(.collapsed) {
                    .menu-arrow {
                        transform: translate(-50%, -50%) rotate(90deg);
                    }
                }
            }
        }
    }

    .side-nav-title {
        margin-top: 10px;
        pointer-events: none;
        cursor: default;
        white-space: nowrap;
        color: $menu-item-color;
        font-weight: $font-weight-bold;
        font-size: calc($menu-item-font-size * 0.85);
        padding: $menu-item-padding-y calc($menu-item-padding-x * 2);
    }


    .menuitem-active {
        >.side-nav-link {
            color: $menu-item-active-color;
            font-weight: $font-weight-medium;
            background-color: $menu-item-active-bg;

            &:hover,
            &:focus,
            &:active {
                color: $menu-item-active-color;
            }

            .menu-arrow {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }

        .menuitem-active {
            .active {
                color: $menu-item-active-color;
                font-weight: $font-weight-medium;
                background-color: transparent;
            }
        }
    }
}

// Sidebar Menu item Arrow (RTL Mode)
*[dir="ltr"] {
    .side-nav {
        .side-nav-item {
            .menu-arrow {
                &:before {
                    content: "\F0141" !important;
                }
            }
        }
    }
}

// Mutli Level Menu
.side-nav-second-level,
.side-nav-third-level,
.side-nav-forth-level {

    li,
    .side-nav-item {
        padding: 0;

        a,
        .side-nav-link {
            display: block;
            font-size: 15px;
            padding: 6px 18px;

            &:hover,
            &:focus,
            &:active {
                color: $menu-item-hover-color;
            }
        }

        &.active {
            >a {
                color: $menu-item-active-color;

                &:hover,
                &:focus,
                &:active {
                    color: $menu-item-active-color;
                }
            }
        }
    }
}

.side-nav-second-level {
    margin-top: 5px;
    padding-left: 20px;
}

.side-nav-third-level,
.side-nav-forth-level {
    padding-left: 40px;
}

// Enlarge Menu (Condensed md size left sidebar )
html[data-sidenav-size="condensed"]{

    .wrapper {

        .navbar-custom {
            z-index: 1005;
            margin-left: $leftbar-width-sm;
        }

        // Side menu
        .leftside-menu {
            position: absolute;
            width: $leftbar-width-sm;
            min-width: $leftbar-width-sm;

            .simplebar-mask,
            .simplebar-content-wrapper {
                overflow: visible !important;
            }

            .simplebar-scrollbar {
                display: none !important;
            }

            .simplebar-offset {
                bottom: 0 !important;
            }

            .logo {
                z-index: 1;
                background: $menu-bg;

                span.logo-lg {
                    display: none;
                }

                span.logo-sm {
                    display: block;
                }
            }

            // Sidebar Menu
            .side-nav {

                .collapse,
                .collapsing {
                    display: none;
                    height: inherit !important;
                    transition: none !important;

                    .side-nav-second-level,
                    .side-nav-third-level,
                    .side-nav-forth-level {
                        display: none !important;
                        padding: 0;
                        margin-top: 0;

                        li {

                            a,
                            .side-nav-link {

                                &::before {
                                    display: none;
                                }
                            }
                        }

                        .menu-arrow {
                            right: $menu-item-padding-x;
                            transform: translate(-50%, -50%);
                        }
                    }
                }

                .side-nav-item {
                    position: relative;

                    .side-nav-link {
                        transition: none;

                        i {
                            font-size: 1.25rem;
                            width: 30px;
                            margin: 0;
                        }

                        &:hover,
                        &:active,
                        &:focus {
                            color: $menu-item-hover-color;
                        }

                        span {
                            display: none;
                        }

                        .menu-arrow {
                            transform: translate(-50%, -50%) rotate(90deg);
                            padding-left: 0 !important;
                        }
                    }

                    &:hover {
                        .side-nav-link {
                            position: relative;
                            // color: $white;
                            background: $menu-condensed-link-bg;
                            width: $leftbar-width;

                            span {
                                display: inline-block;

                                &:not(.badge) {
                                    margin-left: calc($menu-item-padding-x * 4);
                                }
                            }

                            &[data-bs-toggle="collapse"] {
                                border-bottom-right-radius: 0;
                            }
                        }

                        >.collapse,
                        >.collapsing {
                            display: block !important;
                            transition: none !important;

                            >ul {
                                display: block !important;
                                left: $leftbar-width-sm;
                                position: absolute;
                                background: $menu-bg;
                                box-shadow: $box-shadow-sm;
                                width: calc(#{$leftbar-width} - #{$leftbar-width-sm});

                                a {
                                    box-shadow: none;
                                    position: relative;
                                    width: calc(#{$leftbar-width} - #{$leftbar-width-sm});
                                    padding: $menu-item-padding-y calc($menu-item-padding-x * 2);

                                    &:hover {
                                        color: $menu-item-hover-color;
                                    }
                                }

                                li {
                                    &:hover {

                                        >.collapse,
                                        >.collapsing {
                                            display: block !important;
                                            height: auto !important;
                                            transition: none !important;

                                            >ul {
                                                display: block !important;
                                                position: absolute !important;
                                                top: 0;
                                                left: calc(#{$leftbar-width} - #{$leftbar-width-sm});
                                                width: calc(#{$leftbar-width} - #{$leftbar-width-sm});
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .side-nav-title {
            display: none;
        }

        // Content Page
        .content-page {
            margin-left: $leftbar-width-sm;
            min-height: $leftbar-condensed-height; // 1800px
        }
    }
}

//  Compact Menu
html[data-sidenav-size="compact"] {
    .wrapper {

        .navbar-custom {
            margin-left: $leftbar-width-md;
        }

        // Side menu
        .leftside-menu {
            width: $leftbar-width-md;
            min-width: $leftbar-width-md;

            .logo {
                padding: 0 6px;
            }

            // Sidebar Menu
            .side-nav {
                padding: 0;

                .side-nav-title {
                    text-align: center;
                    padding: $menu-item-padding-y calc($menu-item-padding-x);
                }

                .side-nav-item {
                    text-align: center;
                    padding: 0;

                    span.menu-arrow {
                        display: none;
                    }
                }

                .side-nav-link {
                    padding: $menu-item-padding-y $menu-item-padding-x;
                    text-align: center;

                    i {
                        display: block;
                        text-align: center;
                        margin: 0 0 calc($menu-item-padding-y * 1/3);
                        width: auto;
                    }

                    span {
                        margin-left: 0;
                    }

                    .badge {
                        display: none;
                    }
                }


                .side-nav-second-level,
                .side-nav-third-level,
                .side-nav-forth-level {
                    padding: 0;

                    .menu-arrow {
                        display: inline-block;
                        right: $menu-item-padding-x;
                    }

                    li {

                        a,
                        .side-nav-link {
                            padding: calc($menu-item-padding-y * 0.8) calc($menu-item-padding-x);
                            text-align: center;
                        }
                    }
                }
            }
        }

        .menu-arrow {
            display: none;
        }

        // Content Page
        .content-page {
            margin-left: $leftbar-width-md;
        }
    }
}

// Full Size Menu
html[data-sidenav-size="full"] {
    .navbar-custom {
        margin-left: 0;

        .logo-topbar {
            display: inline-block;
        }
    }

    .leftside-menu {
        margin-left: calc($leftbar-width * -1);
        border-color: transparent;
        opacity: 0;

        .logo {
            text-align: left;
        }
    }

    .content-page {
        margin-left: 0;
    }

    &.sidebar-enable {
        .leftside-menu {
            opacity: 1;
            z-index: 1055;
            margin-left: 0;
        }
    }
}

// Layout-boxed (Layout Mode)
@include media-breakpoint-up(xxl) {
    html[data-layout-mode="boxed"] {
        .content {
            margin: 0 auto;
            max-width: $boxed-layout-width;
        }
    }
}

// Scrollable Layout
@include media-breakpoint-up(lg) {
    html[data-layout-position="scrollable"] {

        .wrapper {
            display: block;

            .leftside-menu {
                position: absolute;
                padding-top: 0 !important;

                .logo {
                    position: relative;
                }
            }
        }

        .navbar-custom {
            position: static !important;
        }
    }

    html[data-layout-position="scrollable"][data-layout="topnav"] {

        .topnav {
            position: static;
        }
    }
}