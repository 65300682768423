// --- card -----

.card {
    .img-overlay {

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($dark, .30);
            z-index: 1;
        }

    }

}